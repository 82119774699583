import Box from "@mui/material/Box";

import Header from "components/Header";
import Sidebar from "components/Sidebar";

import AuthenticatedRoutes from "routes/Authenticated";
import NotAuthenticatedRoutes from "routes/NotAuthenticated";

import { useGetLogin } from "hooksQuerys/useLogin";

import "./styles.scss";

export default function Pages() {
  const { data } = useGetLogin();

  return (
    <>
      <Header />

      <Box display="flex">
        {!!data ? (
          <>
            <Box
              sx={{
                maxWidth: { md: "21rem" },
                minWidth: { md: "21rem" },
              }}
            >
              <Sidebar />
            </Box>

            <Box className="area_content">
              <Box style={{ paddingLeft: "1rem" }}>
                <AuthenticatedRoutes />
              </Box>
            </Box>
          </>
        ) : (
          <Box className="area_content no_auth">
            <NotAuthenticatedRoutes />
          </Box>
        )}
      </Box>
    </>
  );
}
