import {
    TSteps,
    TSubject,
    TUnitys,
    TVideos,
    IDataContext,
    TDepeeningKnowledge
} from './data.types';

export const INIT_VALUES_STEPS = {
    0: {
        step: 'ComoEstudar',
        path: '/como-estudar'
    },
    1: {
        step: 'ResumoDaUnidade',
        path: '/resumo-unidade'
    },
    2: {
        step: 'RecursosDeAprendizagem',
        path: '/recursos-aprendizagem'
    },
    3: {
        step: 'Videoaulas',
        path: '/videoaulas',
        videos: [],
    },
    4: {
        step: 'GuiaDeEstudos',
        path: '/guia-estudos'
    },
    5: {
        step: 'PartilhandoIdeias',
        path: '/partilhando-ideias'
    },
    6: {
        step: 'ContribuaComUmDocumento',
        path: '/contribuindo-documento',
    },
    7: {
        step: 'AprofundandoNoConhecimento',
        path: '/aprofundando-conhecimento'
    },
    8: {
        step: 'AvaliarUnidade',
        path: '/avaliar-unidade'
    },
    9: {
        step: 'RoteiroParaAulaPrática',
        path: '/roteiro-pratica'
    },
    10: {
        step: 'VídeosParaAulaPrática',
        path: '/aula-pratica',
        videos: []
    },
} as TSteps;


export const INIT_VALUES_SUBJECT = {
    id: '',
    nome: '',
    capa: '',
    link_partilhando_ideias: '',
    curso_id: undefined,
    orientacoes_avaliacao: '',
} as TSubject;

export const INIT_VALUES_UNITYS = {
    id: undefined,
    titulo_unidade: '',
    referencia_bibliografica: '',
    link_videos_pre_resumo: '',
    objetivos_unidade: '',
    resumo: '',
    guia_de_estudo_b: '',
    guia_de_estudo_a: '',
    guia_de_estudo_c: '',
    infografico: '',
    categoria_unidade: '',
    created_at: '',
    updated_at: '',
    id_area_conhecimento: undefined,
} as TUnitys;

export const INIT_VALUES_VIDEOS = {
    id: undefined,
    id_unidade: undefined,
    titulo_video: '',
    estudio: '',
    is_virtual_lab: 0,
    link_video: '',
    created_at: '',
    updated_at: '',
} as TVideos;

export const INIT_VALUES_DEPEENING_KNOWLEDGE = {
    id: undefined,
    unidade_id: undefined,
    link: '',
    tipo: "Video",
    descricao: '',
} as TDepeeningKnowledge;

export const INIT_VALUES_DATA_CONTEXT  = {
    theme: 'uninta',
    ra: '',
    subject: INIT_VALUES_SUBJECT,
    unitys: [INIT_VALUES_UNITYS],
    learningResources: [],
    videos: [INIT_VALUES_VIDEOS],
    pratic_videos: [INIT_VALUES_VIDEOS],
    script_pratical: { data: { guiaPraticoUrl: '' } },
    depeeningKnowledge: INIT_VALUES_DEPEENING_KNOWLEDGE,
    unitySelected: INIT_VALUES_UNITYS,
    logs: undefined,
    disciplineProgress: 0,
    nextStep: () => {},
    setActivedStep: () => {},
    setUnitySelected: () => {},
    onHandleUpdateLogs: () => {}
} as IDataContext;