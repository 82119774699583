import { memo } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useDataContext from 'context/data.hook';

import { INavigationProps } from '../types';

interface ISelectOfChangeUnityProps {
    navigation: (e: INavigationProps) => void;
}

function SelectOfChangeUnity({ navigation }: ISelectOfChangeUnityProps) {
    const {
        unitys,
        unitySelected,
        setUnitySelected
    } = useDataContext();

    return (
        <FormControl variant="outlined" fullWidth >
            <InputLabel htmlFor="select_unity">Unidade atual:</InputLabel>

            <Select
                id="select_unity"
                label="Unidade atual:"
                value={unitySelected?.id}
                onChange={(e) => {
                    const unityChanged = unitys.filter((unity) => unity.id === e.target.value)[0];
                    
                    setUnitySelected(unityChanged);

                    if (unitys.indexOf(unityChanged) === 0) {
                        navigation({
                            url: '/como-estudar',
                            pathActived: 0,
                            typeLog: "ComoEstudar",
                            changed: true,
                            unity: unityChanged
                        });
                    } else {
                        navigation({
                            url: 'resumo-unidade',
                            pathActived: 1,
                            typeLog: "ResumoDaUnidade",
                            changed: true,
                            unity: unityChanged
                        });
                    }

                }}
            >
                {
                    Array.isArray(unitys) && unitys?.map((unity) => (
                        <MenuItem
                            key={unity.id}
                            value={unity.id}
                        >
                            {unity.titulo_unidade}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default memo(SelectOfChangeUnity);