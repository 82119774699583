import { Suspense } from 'react';

import Drawer from "@mui/material/Drawer";

import Loading from 'pages/components/Loading';
import ListMenuNavigation from "components/ListMenuNavigation";

import { SidebarMobileOpen } from './types';

export default function SidebarMobile({ open, setOpen }: SidebarMobileOpen) {
    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Suspense fallback={<Loading title='Carregando navegação...' />}>
                <ListMenuNavigation setOpen={setOpen}/>
            </Suspense>
        </Drawer>
    )
}