import axios from "axios";
import { ecossistema_api } from "services";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Toastify from 'components/Toastify';

interface SingInProps {
    ra: string;
    password: string;
    idSubject: string;
}

export function useGetLogin() {
    return useQuery("session", verifyAccess);
}

export function SingIn(){
    const queryClient = useQueryClient();

    return useMutation(
        async (values: SingInProps) => {
            return await ecossistema_api.post('nti/login', values)
        }, {
            onSuccess: (data, { ra, idSubject }) => {
                const values = {...data.data, ra, idSubject};

                Toastify({ message: 'Seja Bem-vindo e bons Estudos', type: 'success' });
                sessionStorage.setItem('@md_S', window.btoa(JSON.stringify(values)));
                queryClient.setQueryData('session', (old: any) => ({
                    ...old,
                    ...values
                }));
            },
            onError: () => {
                Toastify({ message: 'RA e/ou senha incorretos.', type: 'warn' });
            }
        }
    );
};

export function verifyAccess() {
    let access: any = sessionStorage.getItem('@md_S');
    let response = null;

    if (access) {
        access = JSON.parse(window.atob(access));

        response = { ...access };
    }

    return response;
}

export async function authenticationMyLibrary(props: { username: string, password: string }) {
    return await axios.post(
        `https://minhabiblioteca.aiamis.com.br/api/companies/1/access`,
        {...props}
    )
}