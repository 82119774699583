import { Helmet as HelmetProvider } from 'react-helmet';

import useDataContext from 'context/data.hook';

export default function Helmet() {
    const { theme } = useDataContext();
    
    return (
        <HelmetProvider>
            <meta name="theme-color" content={theme === 'uninta' ? '#B50000' : '#001D7E'} />
            <meta name="msapplication-TileColor" content={theme === 'uninta' ? '#B50000' : '#001D7E'} />
            <meta name="msapplication-navbutton-color" content={theme === 'uninta' ? '#B50000' : '#001D7E'} />
            <meta name="apple-mobile-web-app-status-bar-style" content={theme === 'uninta' ? '#B50000' : '#001D7E'} />

            {/* Icon Head */}
            <link
                rel="shortcut icon"
                type="image/x-icon"
                href={theme === 'uninta' ? 'https://ecossistema.uninta.edu.br/vendor/images/aiamis.svg' : 'https://dirin.s3.amazonaws.com/ecossistema_v5/assets/f5-logos-03.png'}
            />
        </HelmetProvider>
    )
}