import ReactDOM from 'react-dom';

import ProvidersGlobal from 'providers/ProvidersGlobal';

import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

ReactDOM.render(
    <ProvidersGlobal>
        <App />
    </ProvidersGlobal>
    , document.getElementById('root'));
