import { Suspense, lazy } from "react";
import "./index.scss";

import Drawer from "@mui/material/Drawer";
import Loading from "pages/components/Loading";

const ListMenuNavigation = lazy(() => import("components/ListMenuNavigation"));

export default function Sidebar() {
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{ display: { xs: 'none', md: 'block' } }}
            className="containerMenuNavegacao"
        >
            <div className="containerSidebar">
                <Suspense fallback={<Loading title='Carregando navegação...' />}>
                    <ListMenuNavigation />
                </Suspense>
            </div>
        </Drawer>
    )
}