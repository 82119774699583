import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { LoadingProps } from './types';

export default function Loading({ title }: LoadingProps) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap={2}
            style={{
                minWidth: 240,
                height: '100%'
            }}
        >
            <Typography>
                { title ? title : 'Carregando Itens...' }
            </Typography>
            <CircularProgress />
        </Box>
    )
}