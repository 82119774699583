export const BASESURLS = {
    development: {
        logs: process.env.REACT_APP_URL_API_LOCAL_LOGS,
        material: process.env.REACT_APP_URL_API_LOCAL_MATERIAL,
        ecossistema: process.env.REACT_APP_URL_API_LOCAL_ECOSSISTEMA,
    },
    test: {
        logs: process.env.REACT_APP_URL_API_DEV_LOGS,
        material: process.env.REACT_APP_URL_API_DEV_MATERIAL,
        ecossistema: process.env.REACT_APP_URL_API_DEV_ECOSSISTEMA,
    },
    production: {
        logs: process.env.REACT_APP_URL_API_LOGS,
        material: process.env.REACT_APP_URL_API_MATERIAL,
        ecossistema: process.env.REACT_APP_URL_API_ECOSSISTEMA,
    },
}