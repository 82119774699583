import axios from 'axios';

import { BASESURLS } from './bases';

export const ecossistema_api = axios.create({
    baseURL: BASESURLS[process.env.NODE_ENV].ecossistema
});

export const logs_api = axios.create({
    baseURL: BASESURLS[process.env.NODE_ENV].logs
});

export const material_api = axios.create({
    baseURL: BASESURLS[process.env.NODE_ENV].material
});

/**
 * Setando o [token], [ra_aluno] e [type_user]
 * globalmente no header das requisições
 */
material_api.interceptors.request.use(config => {
    let data: any = sessionStorage.getItem('@md_S');

    if (data) {
        data = JSON.parse(window.atob(data));
    }

    if (!!data && config.headers) {
        config.headers['Authorization'] = `Bearer ${data.AccessToken}`;
        config.headers['ra_aluno'] = data.ra;
        config.headers['type_user'] = data.ra ? 'student' : 'tutor';
    }

    return config;
})