import { useQuery } from 'react-query';
import { material_api } from 'services';

export function useGetVideos(idUnity: number | undefined) {
    return useQuery(
        ['videos', idUnity],
        () => idUnity ? getVideosByIdUnity(idUnity) : null,
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

export function useGetPraticVideos(idUnity: number | undefined) {
    return useQuery(
        ['pratic_videos', idUnity],
        () => idUnity ? getPraticVideosByIdUnity(idUnity) : null,
        {
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}

async function getVideosByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: []
        }
    }
    const { data } = await material_api.get(`unidade/${idUnity}/video-aulas`);
    return data;
}

async function getPraticVideosByIdUnity(idUnity: number | undefined) {
    if (!idUnity) {
        return {
            data: []
        }
    }
    const { data } = await material_api.get(`unidade/${idUnity}/video-praticas`);
    return data;
}